.slightLeftMargin {
  margin-left: 6px !important;
}

.orangeLink {
  color: #f2711c;
}

.orangeLink:hover {
  color: #fd9e25;
}

.ui.button.round {
  border-radius: 3em;
}

html { 
  background: #f5f5f5;
  /* background: url(images/bg.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

body {
  background: none;
}

.ui.container.bottomBumper {
  padding-bottom: 5em;
}

.ui.circular.label.buttonCircleNotification {
  font-size: 0.4em;
  position: absolute;
  top: 0;
  right: 0; 
  margin: 4px 4px 0 0;
}

.weekdaySegment {
  display: inline-block;
  padding: 0 6px;
}

.weekdayLabel {
  display: block;
  margin-bottom: 6px;
  text-align: center;
}

.weekdaySegment .ui.circular.button {
  margin-left: 0.2em;
}

.workdayListWrapper {
  margin-left: 6px;
  display: inline-block;
}

.ui.mini.label.workdayListItem {
  margin-bottom: 3px;
  text-align: center;
  padding: 0.4em;
  vertical-align: middle;
  font-size: 0.5em;
}

.ui.mini.label.workdayListItem .detail {
  padding: 0.4em .5em;
  vertical-align: baseline;
  margin-right: -.4em;
}

.ui.mini.label.workdayListItem > .icon {
  margin: 0;
}

.shiftDescriptionWrapper {
  margin-left: -6px;
}
