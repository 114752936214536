.ui.button.textLinkButton {
  color: #4183c4;
  background: none;
  padding: 0;
  font-weight: normal;
}

.ui.button.textLinkButton:hover {
  color: #1e70bf;
  text-decoration: underline;
}