.mobileWrapper {
  min-height: 100vh;
  min-width: 100%;
}

.pusher.overflowingSidebar {
  min-height: 100vh;
}

.ui.sidebar.overflowingSidebar {
  overflow: visible !important;
}

.navLogo {
  height: 3em;
  margin: 0.75em;
  vertical-align: bottom;
}

.logoNavText {
  font-weight: 900;
  padding-right: 2em;
}

.ui.menu.boxMenu {
  border-radius: 0;
}

.negSideMargins1 {
  margin-left: -1em !important;
  margin-right: -1em !important;
}

.animatedPushable {
  padding-left: 0;
}

.animatedPushable.pushed {
  padding-left: 150px;
  transition: .5s padding ease;
}