.ui.vertical.menu .item>.header.miniHeader {
  font-size: 0.8em;
}

/* Style the video: 100% width and height to cover the entire window */
.heroVideoContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.heroVideo {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

video[poster] {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.heroBackground {
  background: white;
  background-image: url('/logos/Salus_Logo_square_b.png');
  background-size: 200px;
  background-repeat: no-repeat;
  /* background-image: url('https://images.unsplash.com/photo-1501621667575-af81f1f0bacc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80'); */
  background-position: center;
  /* background-size: cover; */
  position: relative;
  overflow: hidden;
}

.darkImageOverlay {
  background: rgba(10, 0, 27, 0.5) !important;
}

.ui.horizontal.smallHeroDivider {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-size: 2em;
}

.quoteBackground {
  background: gray;
  background-image: url('https://images.unsplash.com/photo-1584036533827-45bce166ad94?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1789&q=80');
  background-position: center;
  background-size: cover;
}

.whiteFade {
  background: rgba(255, 255, 255, 0.90);
}

.ui.vertical.segment.white {
  background: white;
}