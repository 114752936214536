.loginBackground {
  background: white;
  background-image: linear-gradient(
    rgba(169, 216, 239, 0.75),
    rgba(18, 53, 132, 0.75)
  );
  /* background-image: linear-gradient(
    rgba(221, 226, 194, 0.75),
    rgba(143, 180, 191, 0.75)
  ),
    url(https://images.unsplash.com/photo-1590826443637-7507b565923d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80); */
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.loginBackgroundOverlay {
  /* background: rgba(0, 0, 0, 0.2); */
  width: 100%;
  height: 100%;
}