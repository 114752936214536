.signupBackground {
  background-image: linear-gradient(
    rgba(169, 216, 239, 0.75),
    rgba(18, 53, 132, 0.75)
  );
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.signupBackgroundOverlay {
  /* background: rgba(0, 0, 0, 0.2); */
  width: 100%;
  height: 100%;
}